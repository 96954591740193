import styled from 'styled-components'

const Box = styled.div`
  background: rgba(183, 173, 209, 0.1);
  border: 1px;
  position: relative;
  min-height: 32px;
  border-radius: 24px;
  font-weight: bold;
  font-size: 14px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.1) inset;
`
const ActiveBoxContainer = styled(Box)`
  border: 1px solid rgba(241, 209, 227, 0.6)
  border-radius: 24px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.1) inset;
`

const EBoxSm = ({ children, isActive = false, ...rest }) => {
  if (isActive) {
    return <ActiveBoxContainer {...rest}>{children}</ActiveBoxContainer>
  }

  return <Box {...rest}>{children}</Box>
}

export default EBoxSm
